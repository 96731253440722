import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import AllWebinars from '../../components/allWebinars'
import { Button } from '../../components/global'

const W003 = () => (
  <Layout includeNotice={false}>
    <SEO title="Webinar 003: How To Troubleshoot Any Electrical Circuit on Any Vehicle (Part 1 of 2)" />
    <div className="container container-last">
      <h1>
        Webinar 003:
        <br />
        How To Troubleshoot Any Electrical Circuit on Any Vehicle (Part 1 of 2)
      </h1>
      <hr />
      <p>
        There are eight ways a vehicle electrical circuit can fail. Any electrical circuit on any
        vehicle can only experience eight possible basic circuit problems. In this Webinar, W003
        Part 1, we explain and illustrate the first five ways a circuit can fail and the
        troubleshooting procedure that reveals what the problem is in about one minute or less. This
        is foolproof electrical troubleshooting that can be performed on any electrical circuit in
        any automobile, truck or piece of heavy equipment, boat or private aircraft and may not
        require a circuit schematic diagram. Just follow this simple test procedure.
      </p>
      <ul style={{ marginBottom: 0 }}>
        <li>Watch Webinar W003 Session</li>
        <li>
          Purchase a link to download the Webinar W003 recording and watch as often as you like.
          (Download link expires after 7 days)
        </li>
      </ul>
      <Button
        type="secondary"
        text="BUY NOW for $39.00"
        href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=091adc64e1834c659862cee6c34a8a36"
      />
      <hr />
      <AllWebinars />
    </div>
  </Layout>
)

export default W003
